<template>
  <v-dialog value="true" width="500" persistent>
    <v-card>
      <v-card-title primary-title>
        <img :src="require('@/assets/shield.svg')" style="margin-right:1em;" contain height="50" alt="Covenant Shield Logo" />
        Board Portal Password Reset
      </v-card-title>
      <v-stepper v-model="step">
        <v-stepper-header>
          <v-stepper-step step="1" :complete="step > 1">Email</v-stepper-step>
          <v-stepper-step step="2" :complete="step > 2">Validate</v-stepper-step>
          <v-stepper-step step="3">Reset</v-stepper-step>
        </v-stepper-header>
        <v-stepper-items>
          <v-stepper-content step="1">
            <v-card>
              <v-card-text height="150px">
                <v-form ref="emailForm">
                  <p>Enter your email address which is set up with access to the Board portal. You will then be sent a validation code to reset your password.</p>
                  <v-text-field label="Email Address" ref="emailField" v-model="email" :rules="[required, emailRule]" outlined @keyup.enter="checkEmail"></v-text-field>
                </v-form>
              </v-card-text>
              <v-card-actions>
                <v-btn color="primary" @click="checkEmail">Send Validation Code</v-btn>
              </v-card-actions>
            </v-card>
          </v-stepper-content>
          <v-stepper-content step="2">
            <v-card>
              <v-card-text height="150px">
                <v-form ref="validateForm">
                  <p>You were just emailed a validation code to the given email address. Please copy-and-paste that validation code below.</p>
                  <v-text-field label="Validation Code" v-model="validationCode" :rules="[required]" outlined></v-text-field>
                </v-form>
              </v-card-text>
              <v-card-actions>
                <v-btn color="primary" outlined @click="step--">Back</v-btn>
                <v-btn color="primary" @click="checkValidation">Next</v-btn>
              </v-card-actions>
            </v-card>
          </v-stepper-content>
          <v-stepper-content step="3">
            <v-card>
              <v-card-text height="150px">
                <v-form ref="passwordForm">
                  <v-text-field label="Password" v-model="password" :rules="[required,pwd,minLength8]" type="password" outlined></v-text-field>
                  <v-text-field label="Password (again)" v-model="passwordSec" :rules="[required,pwd,minLength8,pwdMatch]" type="password" outlined></v-text-field>
                </v-form>
              </v-card-text>
              <v-card-actions>
                <v-btn color="primary" @click="setPassword">Set Password</v-btn>
              </v-card-actions>
            </v-card>
          </v-stepper-content>
        </v-stepper-items>
      </v-stepper>
    </v-card>
  </v-dialog>
</template>
<script>
import { ref } from '@vue/composition-api'
import { useValidation } from '../../helpers/validation'

export default {
  setup (props, { root }) {
    const { required, email: emailRule, pwd, minLength8 } = useValidation()
    const step = ref(1)
    const email = ref('')
    const emailForm = ref(null)
    // const emailFormValid = ref(false)
    function checkEmail (e) {
      if (e) e.preventDefault()
      if (emailForm.value.validate()) {
        root.$feathers.service('board/reset').create({ email: email.value.toLowerCase() }).catch((e) => {
          alert('Error sending unique validation code: ' + e)
        }).then(() => {
          step.value++
        })
      }
    }

    const validationCode = ref('')
    const validateForm = ref(null)
    function checkValidation () {
      if (validateForm.value.validate()) {
        root.$feathers.service('board/reset').create({ email: email.value.toLowerCase(), uniqueCode: validationCode.value }).catch((e) => {
          alert('Error validating code: ' + e)
        }).then(({ data }) => {
          if (data.length > 0) {
            userId.value = data[0]._id
            step.value++
          } else {
            alert('An error occured loading your user details. Please contact the President\'s Office for assistance.')
          }
        })
      }
    }

    const userId = ref('')

    const password = ref('')
    const passwordSec = ref('')
    const passwordForm = ref(null)
    const pwdMatch = (v) => {
      return password.value === passwordSec.value || 'Passwords must match'
    }

    function setPassword () {
      if (passwordForm.value.validate()) {
        // Validate the password
        if (password.value !== passwordSec.value) {
          alert('Passwords do not match')
        } else {
          root.$feathers.service('board/reset').patch(userId.value, { email: email.value.toLowerCase(), code: validationCode.value, password: password.value }).catch((e) => {
            alert('Error resetting password: ' + e)
          }).then(() => {
            root.$store.dispatch('main/snackbar', { color: 'success', text: 'Password reset successfully', timeout: 6000, active: true })
            root.$router.push('/board')
          })
        }
      }
    }

    return {
      required,
      emailRule,
      pwd,
      minLength8,
      step,
      email,
      emailForm,
      checkEmail,
      validationCode,
      validateForm,
      checkValidation,
      password,
      passwordSec,
      passwordForm,
      pwdMatch,
      setPassword
    }
  }
}
</script>
